@import "_global";

@import "sections/_header";
@import "sections/_navbar";
@import "sections/_footer";

@import "widgets/_flipboxes";
@import "widgets/_socialmedia";
@import "widgets/_sidebar";

@import "pages/_index";
@import "pages/_inner-pages";

@import "_custom";