#inner-page {
  .infographic {
    margin: auto;
    display: block;
  }

  h1 {
    text-align: center;
  }

  .innerpageimg {
    border-radius: 10px;
    float: right;
    padding: 5px;
    margin-left: 1rem;
    max-width: 295px;
  
    &.left {
      float: left;
    }
  
    &.centre {
      display: block;
      float: none;
      margin: auto;
      max-width: 250px;
    }
  
    @media (max-width: 991px) {
      float: none;
      margin: auto;
      width: 100%;
  
      &.left {
        float: none;
      }
  
      &.centre {
        max-width: 100%;
      }
    }
  }

  .infographicbookingtext {
    color: $primary-color;
  }
}

#mobile-booking-tool {
  background: $primary-color;
  padding: 15px;
  border-radius: 10px;

  h2,
  p {
    color: #fff;
    text-align: center;
  }

  @media (min-width: 767px) {
    display: none;
  }
}